<template>
  <topBanner />
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sigma_about mt-5 mt-lg-0">
            <h3 class="title">{{ hireData.section1.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section1.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h3 class="title mt-5">
              {{ hireData.section2.heading }}
            </h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section2.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h3 class="title mt-5">{{ hireData.section3.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p :class="item.classname" v-for="item in hireData.section3.desc" :key="item.id">
                {{ item.row }}
                <ul class="data">
                  <li v-for="item2 in item.info" :key="item2.id">{{ item2.row }}</li>
                </ul>
              </p>
            </div>

            <h3 class="title mt-5">{{ hireData.section4.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section4.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h3 class="title mt-5">{{ hireData.section5.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section5.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h3 class="title mt-5">{{ hireData.section6.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section6.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h3 class="title mt-5">{{ hireData.section7.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section7.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h3 class="title mt-5">{{ hireData.section8.heading }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in hireData.section8.desc" :key="item.id">{{ item.row }}</p> 
            </div>
          </div>
        </div>
      </div>
      <!--End of row-->
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import data from '../Data/data.json'
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      hireData: ''
    };
  },
  created(){
    this.hireData = data.hire
  }
};
</script>

<style>
.data{
    margin-left: 30px;
}
.data li{
    list-style: circle;
}
.warning-text{
    font-weight: bold;
}
</style>